import axios from 'axios';

const baseURL = '/';

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    withCredentials: true,
    'Content-Type': 'application/json',
  },
});
