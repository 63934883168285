import fs from 'fs';
import { useEffect } from 'react';
import HelmetLayout from '@/helmet/index';
import { useSessionStorage } from 'react-use';
import { HomePage } from '@/common/components/Home';

export default function Home({ dynamicData }: any) {
  function isObjEmpty(obj = {}) {
    return Object!.keys(obj).length === 0;
  }
  const [localAds, setSessionValue] = useSessionStorage('ads', {});
  useEffect(() => {
    if (!isObjEmpty(localAds)) {
      setSessionValue({});
    }
  }, []);

  return (
    <HelmetLayout
      title={dynamicData.landing_page_SEO_title}
      description={dynamicData.landing_page_SEO_description}
    >
      <HomePage dynamicData={dynamicData} />
    </HelmetLayout>
  );
}

export async function getStaticProps() {
  const response = await fs.promises.readFile(
    `public/dynamic/${process.env.NEXT_PUBLIC_PROJECT}/data.json`
  );
  const data = JSON.parse(response.toString());
  return {
    props: {
      dynamicData: data,
    },
  };
}
