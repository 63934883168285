import { useState } from 'react';
import Image from 'next/image';
import { Htag } from '@/components/index';
import { FrequentlyAskedQuestionsItemProps } from './FrequentlyAskedQuestionsItem.props';

const FrequentlyAskedQuestionsItemMobile = ({
  id,
  text,
  title,
  section,
}: FrequentlyAskedQuestionsItemProps) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="flex flex-col w-382px mb-2">
      <div
        className="flex justify-between items-center bg-white w-382px p-4"
        onClick={() => setToggle(!toggle)}
      >
        <Htag
          text={title}
          tag="h3"
          id={section ? `lp_faq_question_${id}` : `faq_faq_question_${id}`}
          styles="w-60 font-roboto text-black-strong font-medium text-base"
        />
        <div>
          <Image
            src={`/icons/${toggle ? 'arrowUp' : 'arrowDown'}.svg`}
            alt="CatThrowingVase"
            width={29}
            height={29}
          />
        </div>
      </div>
      {toggle && (
        <div className="flex flex-col bg-white w-382px mt-2 py-4 pl-4 pr-8">
          <span
            id={section ? `lp_faq_answer_${id}` : `faq_faq_answer_${id}`}
            className="font-roboto w-310px font-normal text-base text-grey-lighten"
          >
            {text}
          </span>
        </div>
      )}
    </div>
  );
};

export default FrequentlyAskedQuestionsItemMobile;
