import React from 'react';
import { RepeatElementHOCProps } from './RepeatElementHOC.props';

const RepeatElementHOC: React.FC<RepeatElementHOCProps> = ({
  children,
  count,
}) => {
  const stars: JSX.Element[] = [];
  for (let i = 1; i <= count; i++) {
    stars.push(children);
  }

  return <React.Fragment>{stars}</React.Fragment>;
};

export default React.memo(RepeatElementHOC);
