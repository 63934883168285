import { Htag } from '@/components/index';
import { FrequentlyAskedQuestionsItemProps } from './FrequentlyAskedQuestionsItem.props';

const FrequentlyAskedQuestionsItem = ({
  id,
  text,
  title,
  section,
}: FrequentlyAskedQuestionsItemProps) => {
  return (
    <div className="flex flex-col items-center bg-white w-382px py-12 px-9 mb-9 h-full">
      <Htag
        tag="h3"
        styles="text-primary font-roboto font-bold text-2xl mb-4 w-full"
        text={title}
        id={section ? `lp_faq_question_${id}` : `faq_faq_question_${id}`}
      />
      <span
        id={section ? `lp_faq_answer_${id}` : `faq_faq_answer_${id}`}
        className="font-roboto font-normal text-lg text-grey-lighten"
      >
        {text}
      </span>
    </div>
  );
};

export default FrequentlyAskedQuestionsItem;
