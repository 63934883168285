import Link from 'next/link';
import { paths } from 'routes/index';
import { Htag, Button } from '@/components/index';
import InsuranceQuotesItem from './InsuranceQuotesItem';

const InsuranceQuotes = ({ dynamicData }: any) => {
  return (
    <div className="flex w-full justify-center bg-pink-light pb-39px md:pb-16 pt-53px md:pt-16">
      <div className="flex flex-col md:px-42px xl:px-0 w-1207px">
        <div className="flex justify-center text-center w-full">
          <Htag
            text={dynamicData.landing_page_insurance_quotes_section_title}
            tag="h2"
            id="lp_threesteps_title"
            styles="font-playfair text-primary text-34px font-bold md:text-42px mb-39px md:mb-70.5px md:w-512px w-348px"
          />
        </div>
        {dynamicData.landing_page_insurance_quotes_section_items.map(
          (insuranceQuotesItem: any) => {
            return (
              <InsuranceQuotesItem
                imgName={insuranceQuotesItem.imgName}
                title={insuranceQuotesItem.title}
                subTitle={insuranceQuotesItem.subTitle}
                trakId={insuranceQuotesItem.trakId}
                textId={insuranceQuotesItem.textId}
                logoId={insuranceQuotesItem.logoId}
              />
            );
          }
        )}
        <div className="flex justify-center">
          <Link href={paths.quoteFirstPage}>
            <Button
              id="home_btn_1"
              text={dynamicData.landing_page_insurance_quotes_section_button}
              styles="justify-self-center inline-block w-fit bg-primary text-white rounded-3xl py-2 px-6"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InsuranceQuotes;
