import Image from 'next/image';
import { RepeatElementHOC } from '@/common/HOC/RepeatElementHOC';

const InsuranceProvidersItem = ({ insuranceProvidersDataItem, star }: any) => {
  const {
    id,
    width,
    height,
    rating,
    coverageTypes,
    coverageDetails,
    claimReimbursementTime,
    insuranceProviderImgUrl,
  } = insuranceProvidersDataItem;
  return (
    <div className="w-360px md:w-full flex justify-between">
      <div className="flex w-249px justify-start pl-3">
        <Image
          src={insuranceProviderImgUrl}
          alt={`insuranceProvider_${id}`}
          width={width}
          height={height}
          id={`lp_table_logo_row_${id}`}
          unoptimized
          className="my-10"
        />
      </div>
      <div id={`lp_table_rating_row_${id}`} className="flex w-249px">
        <RepeatElementHOC count={rating}>
          <Image
            src={star}
            alt="rating"
            width={29}
            height={29}
            className="pl-1"
          />
        </RepeatElementHOC>
      </div>
      <div className="flex items-center">
        <span
          id={`lp_table_coverage_details_text_row_${id}`}
          className="hidden md:block text-grey-lighten mr-9 text-lg font-roboto w-249px bg-white"
        >
          {coverageDetails}
        </span>
        <span
          id={`lp_table_coverage_types_text_row_${id}`}
          className="hidden lg:block text-grey-lighten text-lg font-roboto w-249px"
        >
          {coverageTypes}
        </span>
        <span
          id={`lp_table_coverage_types_text_row_${id}`}
          className="hidden xl:block text-grey-lighten text-lg font-roboto w-249px text-center"
        >
          {claimReimbursementTime}
        </span>
      </div>
    </div>
  );
};

export default InsuranceProvidersItem;
