declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const getQuoteButtonClick = async (
  event: string,
  id: string,
  value: string,
  click_id: string,
  adId: string,
  bid: number,
  position: number,
  adProvider: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    id: id,
    click_id,
    ad_id: adId,
    event: event,
    value: value,
    ad_bid_price: bid,
    position: position,
    ad_provider: adProvider,
  });
};

export const dataLayerHandler = async (
  event: string,
  id: string,
  value?: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    id: id,
    event: event,
    value: value,
  });
};

export const getSessionId = (key: string) => {
  let session_id = '';
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().split('.');
    const firstPart = cookie[0].split('_');
    const val = firstPart[2]?.split('=')[0];
    if (val === key) {
      session_id = cookie[2];
    }
  }
  return session_id || ' ';
};
