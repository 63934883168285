import Image from 'next/image';
import { Htag } from '@/components/index';
import { InsuranceQuotesItemProps } from './InsuranceQuotesItem.props';

const InsuranceQuotesItem = ({
  title,
  logoId,
  trakId,
  textId,
  imgName,
  subTitle,
}: InsuranceQuotesItemProps) => {
  return (
    <div className="flex flex-col items-center md:flex-row mb-14">
      <Image
        src={imgName}
        alt={`item_${trakId}`}
        width={148}
        height={148}
        id={logoId}
      />
      <div className="flex flex-col items-center md:items-start ml-0 md:ml-9 mt-39px md:mt-0 px-6 md:px-0">
        <Htag
          id={trakId}
          tag="h3"
          text={title}
          styles="font-roboto text-primary text-22px md:text-2xl font-bold mb-31px md:mb-0"
        />
        <span
          id={textId}
          className="font-roboto text-grey-lighten text-lg md:text-22px font-normal text-center md:text-start"
        >
          {subTitle}
        </span>
      </div>
    </div>
  );
};

export default InsuranceQuotesItem;
