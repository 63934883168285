import Image from 'next/image';
import { Htag } from '@/components/index';
import FrequentlyAskedQuestionsItem from './FrequentlyAskedQuestionsItem';
import FrequentlyAskedQuestionsItemMobile from './FrequentlyAskedQuestionsItemMobile';

const FrequentlyAskedQuestions = ({ dynamicData, section = false }: any) => {
  return (
    <div className="bg-pink-light flex flex-col items-center md:pt-12 pt-42px">
      <Htag
        id={section ? 'lp_faq_headline' : 'faq_faq_headline'}
        tag="h2"
        text={dynamicData.landing_page_frequently_asked_questions_title}
        styles="font-playfair font-bold md:text-42px text-34px text-primary text-center"
      />
      <div
        className="md:pt-16 pt-42px md:w-750px lg:w-900px xl:w-1214px flex flex-col md:items-center
      md:flex-column md:justify-items-center
      lg:flex-row lg:justify-between lg:flex-wrap lg:items-start
     "
      >
        <Image
          src={dynamicData.landing_page_frequently_asked_questions_image}
          alt="frequently_asked_questions_image"
          width={382}
          height={366}
          className="mb-6"
        />
        {dynamicData.landing_page_frequently_asked_questions_items.map(
          (frequentlyAskedQuestionsItem: any) => (
            <div
              key={frequentlyAskedQuestionsItem.id}
              className="md:last:mb-0 last:mb-6"
            >
              <div className="hidden md:block ">
                <FrequentlyAskedQuestionsItem
                  title={frequentlyAskedQuestionsItem.title}
                  text={frequentlyAskedQuestionsItem.text}
                  section={section}
                  id={frequentlyAskedQuestionsItem.id}
                />
              </div>
              <div className="block md:hidden">
                <FrequentlyAskedQuestionsItemMobile
                  section={section}
                  title={frequentlyAskedQuestionsItem.title}
                  text={frequentlyAskedQuestionsItem.text}
                  id={frequentlyAskedQuestionsItem.id}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
