import InsuranceProvidersItem from './InsuranceProvidersItem';

const InsuranceProviders = ({ dynamicData }: any) => {
  return (
    <div className="bg-white flex justify-center pb-51px md:pt-87px pt-39px overflow-x-auto">
      <div className="w-360px md:w-994px flex flex-col items-center">
        <span
          id="lp_table_title"
          className="text-42px text-primary font-playfair font-bold mb-42px text-center"
        >
          {dynamicData.landing_page_insurance_providers_title}
        </span>
        <div className="flex flex-col">
          <div className="w-full flex md:justify-between pb-7">
            <div
              id="lp_table_insurance_title"
              className="text-primary font-bold font-roboto text-xl pl-3 xl:w-1/5 lg:w-1/4 md:w-1/3 w-1/2"
            >
              {dynamicData.landing_page_insurance_providers_first_column}
            </div>
            <div
              id="lp_table_rating_title"
              className="text-primary font-bold font-roboto text-xl md:text-start text-center md:ml-0 xl:w-1/5 lg:w-1/4 md:w-1/3 w-1/2 md:pl-7 pl-2 "
            >
              {dynamicData.landing_page_insurance_providers_second_column}
            </div>
            <div
              id="lp_table_coverage_details_title"
              className="hidden md:block text-primary font-bold font-roboto text-xl xl:w-1/5 lg:w-1/4 w-1/3 pl-7"
            >
              {dynamicData.landing_page_insurance_providers_third_column}
            </div>
            <div
              id="lp_table_coverage_types_title"
              className="hidden lg:block text-primary font-bold font-roboto text-xl xl:w-1/5 w-1/5 pl-7"
            >
              {dynamicData.landing_page_insurance_providers_fourth_column}
            </div>
            <div
              id="lp_table_claim_title"
              className="hidden xl:block text-primary font-bold font-roboto text-xl w-1/5 "
            >
              {dynamicData.landing_page_insurance_providers_fifth_column}
            </div>
          </div>
          <hr className="text-grey" />
          {dynamicData.landing_page_insurance_items.map(
            (insuranceProvidersDataItem: any) => (
              <div key={insuranceProvidersDataItem.id}>
                <InsuranceProvidersItem
                  insuranceProvidersDataItem={insuranceProvidersDataItem}
                  star={
                    dynamicData.landing_page_insurance_providers_rating_image
                  }
                />
                <hr className="text-gray" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default InsuranceProviders;
