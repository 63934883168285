import { InsuranceQuotes } from '@/common/components/Home/InsuranceQuotes';
import { InsuranceProviders } from '@/common/components/Home/InsuranceProviders';
import { PetInsuranceMarketplace } from '@/common/components/Home/PetInsuranceMarketplace';
import { FrequentlyAskedQuestions } from '@/common/components/Home/FrequentlyAskedQuestions';

const HomePage = ({ dynamicData }) => {
  const elements = {
    landing_page_hero_section: (
      <PetInsuranceMarketplace dynamicData={dynamicData} />
    ),
    landing_page_insurance_quotes_section: (
      <InsuranceQuotes dynamicData={dynamicData} />
    ),
    landing_page_insurance_providers: (
      <InsuranceProviders dynamicData={dynamicData} />
    ),
    landing_page_frequently_asked_questions: (
      <FrequentlyAskedQuestions dynamicData={dynamicData} />
    ),
  };
  const order = dynamicData.landing_page_order;
  const orderedElements = Object.keys(order)
    .sort((a, b) => order[a] - order[b])
    .map((key) => elements[key]);
  return <div className="flex flex-col w-full">{orderedElements}</div>;
};

export default HomePage;
