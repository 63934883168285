import { z } from 'zod';
import Image from 'next/image';
import { api } from '@/constants/api';
import { useRouter } from 'next/router';
import { getSessionId } from '@/utils/index';
import { useWindowSize } from '@/hooks/index';
import { useSessionStorage } from 'react-use';
import { Events } from '@/globalContext/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { axiosInstance } from '@/axios/axiosInstance';
import { Button, Htag, Input } from '@/components/index';
import { Partners } from '@/components/Partners/Partners';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

const PetInsuranceMarketplace = ({ dynamicData }: any) => {
  const router = useRouter();

  let sessionValue: string | undefined;
  if (typeof window !== 'undefined' && sessionStorage) {
    sessionValue = sessionStorage.url_object;
  }

  const urlHashMap = new Map();
  const routhAsPath = router.asPath.split('?')[1];
  const urlParams = new URLSearchParams(routhAsPath);
  urlParams?.forEach((value, key) => {
    urlHashMap.set(key, value);
  });
  const urlObject: { [key: string]: any } = {};
  urlParams?.forEach((value, key) => {
    urlObject[key] = value;
  });

  const [, setSessionValue] = useSessionStorage('ads', {});

  const { width, height } = useWindowSize();
  const FormSchema = z.object({
    zip: z
      .string({
        invalid_type_error: 'Zip Code must be a number',
      })
      .min(5, { message: 'Zip Code must contain 5 characters' }),
  });

  type FormSchemaType = z.infer<typeof FormSchema>;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormSchemaType>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(FormSchema),
  });

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    const sessionValueParsed = sessionValue && JSON.parse(sessionValue);
    const parsedSessionValue =
      sessionValueParsed && JSON.parse(sessionValueParsed);
    const click_id = parsedSessionValue?.gclid || '';
    const sendingData = {
      form_zip: data.zip,
      local_hour: new Date().getHours(),
      user_language: 'en_US',
      ga_session_id: getSessionId(
        process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_SHORT_KEY || ''
      ),
      ga_client_id: window?.gaGlobal?.vid || '',
      click_id: urlObject?.gclid || click_id || '',
    };
    Events.emit('HIDE_HEADER', {});
    try {
      const str = JSON.stringify(sendingData);
      const data = await axiosInstance.post(api || '', str);
      if (data.status === 200 && !data.data.error) {
        setSessionValue(data.data);
        await router.push('thank_you');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      Events.emit('SHOW_HEADER', {});
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        gtm-parent-el="Landing"
        gtm-id="Hero_Section"
        id="Hero_Section"
        className="Hero_Section bg-white pb-60px px-6 font-roboto"
      >
        <div
          style={{ maxWidth: '1000px' }}
          className="w-full mx-auto md:flex md:text-left text-center items-center"
        >
          <div className="h1" style={{ maxWidth: '485px' }}>
            <Htag
              gtm-parent-el="lp_hero_headline"
              gtm-id="Landing_Page_Headline"
              id="lp_hero_headline"
              elementType="landing_page_headline"
              tag="h1"
              styles="Hero_Section, Landing_Page_Headline text-5xl mb-4 font-bold text-primary font-playfair mt-5 md:mt-51px md:mt-0"
              text={dynamicData.landing_page_hero_section_title}
            />
            <div
              gtm-parent-el="Hero_Section"
              gtm-id="Sub_Headline"
              id="lp_hero_subheading"
              className="ero_Section, Sub_Headline mb-4 md:px-0 px-5 text-lg font-normal text-grey"
            >
              {dynamicData.landing_page_hero_section_sub_title}
            </div>
            <div
              className="w-full flex justify-center md:justify-start"
              style={{ position: 'relative' }}
            >
              <Input
                value={useWatch({ control, name: 'zip' })}
                maxLength={5}
                type="number"
                track_id="lp_hero_zip"
                borderLight
                customPlaceholder
                styles="w-full rounded-13px "
                placeholder={
                  dynamicData.landing_page_hero_section_input_placeholder
                }
                register={register('zip')}
                defaultValue={''}
                disabled={isSubmitting}
                errors={!!errors.zip || false}
                errorText={errors?.zip?.message}
                inputTextStyles={{
                  fontSize: '14px',
                  width: width <= 768 ? '100%' : '200px',
                  height: '35px',
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                }}
                className="md:left-[179px] left-[230px] hidden md:block"
              >
                <Button
                  id="lp_hero_cta"
                  type="submit"
                  text={dynamicData.landing_page_hero_section_button_text}
                  styles="bg-primary text-white rounded-5px w-[214px] h-[50px] text-lg py-2"
                />
              </div>
            </div>
            <div className="w-full block md:hidden w-full flex flex-col">
              <Button
                id="lp_hero_cta_mobile"
                type="submit"
                text={dynamicData.landing_page_hero_section_button_text}
                styles={`${
                  errors?.zip?.message ? 'mt-[40px]' : 'mt-[24px]'
                }  bg-primary text-white w-full rounded-5px text-lg py-2  mt-[40px]`}
              />
            </div>
          </div>
          <div className="mt-[50px] md:mt-0">
            <Image
              id="lp_hero_image"
              src={dynamicData.landing_page_hero_section_title_image}
              alt="catAndDog"
              width={480}
              height={410}
              loading="eager"
            />
          </div>
        </div>
        <Partners
          title={dynamicData.landing_page_hero_section_partnership_title}
          subTitle={dynamicData.landing_page_hero_section_partnership_sub_title}
          logos={dynamicData.landing_page_hero_section_partnership_images}
        />
        <div
          style={{ maxWidth: '1160px' }}
          className="w-full flex md:flex-row flex-col justify-between items-center mx-auto"
        >
          <Image
            src={dynamicData.landing_page_hero_section_description_image}
            alt="landing_page_hero_section_description_image"
            width={350}
            height={350}
            id="lp_message_image"
          />
          <div
            gtm-parent-el="Hero_Section"
            gtm-id="Description"
            id="Hero_Section, Description"
            className="Hero_Section, Description font-normal md:mx-20 px-6 md:text-2xl md:mt-0 mt-4 text-lg text-grey-darken"
          >
            {dynamicData.landing_page_hero_section_description}
          </div>
        </div>
      </div>
    </form>
  );
};

export default PetInsuranceMarketplace;
