import NextHead from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { HtagProps } from '@/helmet/helmet.props';

const HelmetLayout = ({ title, children, description }: HtagProps) => {
  const router = useRouter();

  return (
    <>
      <NextHead>
        <title>{title}</title>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#033159" />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="language" content="English" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="ir-site-verification-token" content="-1030729427" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=6.0, user-scalable=1"
        />
        <meta name="ir-site-verification-token" content="-1030729427" />
        <link rel="apple-touch-icon" href="/pwaIcons/apple-touch-icon.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" />

        <link
          rel="canonical"
          href={`https://www.coverrover.com/${router.pathname}`}
        />
      </NextHead>
      <Script src="//insurance.mediaalpha.com/js/serve.js" />
      <div className="global-theme">
        <main>{children}</main>
      </div>
      <Script
        type="text/javascript"
        src="https://classic.avantlink.com/affiliate_app_confirm.php?mode=js&authResponse=4262bebb23636c4f555566fcb505384fa71057e2"
      />
    </>
  );
};

export default HelmetLayout;
